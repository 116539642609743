<template>
  <div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['安全考核', '客服考核']"
        :key="index"
        :class="{ activeColor: typeBtnIndex == index }"
        @click="changeTypeBtn(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="select-box">
      <div>
        <span class="_Select">
          <span style="width: 50px">公司：</span>
          <el-select
            v-model="company"
            clearable
            placeholder="请选择公司"
            @change="changeCompy"
          >
            <el-option
              v-for="item in companyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </span>

        <span class="_Select">
          <span style="width: 70px">状态：</span>
          <el-select
            v-model="status"
            clearable
            placeholder="请选择状态"
            @change="bindCheckBox"
          >
            <el-option
              v-for="item in [
                { label: '已完成', value: 2 },
                { label: '未完成', value: 4 },
                { label: '有问题点', value: 5 },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>
      </div>
      <el-button type="primary" class="edit" @click="toChoose"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
    </div>
    <div class="_TableBox" style="margin-top: 10px">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        highlight-current-row
        @row-dblclick="toTowPage"
        @selection-change="handleSelectionChange"
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRow"
        :cell-style="cellStyle"
      >
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <!-- <el-table-column label="岗位" prop="postName" width="120">
        </el-table-column> -->
        <el-table-column prop="companyName" label="所属公司" width="230">
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.status === 0">未抽题</span>
            <span v-show="scope.row.status === 1">已抽题</span>
            <span v-show="scope.row.status === 2">完成</span>
            <span v-show="scope.row.status === 3">整改完成</span>
            <span v-show="scope.row.status === 4">未完成</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="completeTime"
          label="完成时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saveUserName"
          label="创建人"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="ruleRemark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <i
              v-if="CurId == scope.row.id"
              class="el-icon-delete"
              @click="delItem"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>

    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="45%">
      <div style="display: flex" class="text">
        <span style="margin: 10px">添加备注:</span
        ><textarea v-model="remark" style="padding: 10px"></textarea>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
          <div class="topic">
            <div>文件名称</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt=""
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div style="display: flex">
              <span
                style="
                  margin: 10px;
                  margin-left: 1.4%;
                  margin-bottom: 0px;
                  margin-top: 0px;
                "
                >&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&ensp;</span
              >
              <div class="topic1">
                <div>{{ titlelist.fileName }}</div>
                <div class="Centernumbet">
                  <span @click="tocheckCount(titlelist.questionCount)">{{
                    titlelist.questionCount
                  }}</span>
                </div>
                <div id="number">
                  <img
                    @click="handleDrawnNumber(0)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled; margin-top: 3%"
                    v-model="extractCount"
                  />
                  <img
                    @click="handleDrawnNumber(1)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="drawQuestions">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="题目详情" :visible.sync="dialogVisibletitle" width="30%">
      <el-form label-width="80px">
        <el-form-item label="专属">
          <el-input v-model="Stitle"></el-input>
        </el-form-item>
        <el-form-item label="通用">
          <el-input v-model="commontitle"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibletitle = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibletitle = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      dialogVisible: false,
      dialogVisibletitle: false,
      option2: [],
      tableData: [],
      Company: "",
      Area: "",
      Stitle: "",
      commontitle: "",
      Company1: "",
      Area1: "",
      isshow: false,
      number: "",
      curArea: "",
      titlelist: {},
      CurId: "",
      CurName: "",
      deptmentName: "",
      deptmentName1: "",
      option3: [],
      curPost: "",
      status: 4,
      start: 0,
      end: 10,
      answer: "",
      currentPage1: null,
      curSize: 10,
      Ctdata: "",
      dialogVisibleC: false,
      defaultProps: {
        label: "auditTypeName",
        children: "auditTypelist",
      },
      year: "2022",
      showTypeBtn: true,
      typeBtnIndex: 0,
      company: "",
      companyList: [],
      extractCount: 0,
      remark: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "RulesList") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.getElementHeight(); 
      this.companyList = this.$store.state.companyUserList;
      this.company = this.$store.state.userInfo.upCompanyId;
      this.getData();
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  methods: {
    changeCompy() {
      this.showTypeBtn = true;
      this.getData();
    },
    getData() {
      if (this.dialogVisible) this.status = 4;
      get(
        "/api/ExamineRules/GetByFileAudit?CompId=" +
          this.company +
          "&Name=" +
          (this.typeBtnIndex == 0 ? "安全考核" : "客服考核") +
          "&Status=" +
          this.status
      ).then((res) => {
        this.tableData = res.data;
        if (this.dialogVisible) {
          this.dialogVisible = false;
          this.toTowPage(res.data[0]);
        }
      });
    },
    tocheckCount(e, v) {
      this.Stitle = e - v;
      this.commontitle = v;
      // this.dialogVisibletitle = true;
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 35 + 64 + 70); //70是div底部距离底部的高度
      });
    },
    handleDrawnNumber(i) {
      var titlelist = this.titlelist;
      if (titlelist.questionCount == 0) {
        this.$message({
          type: "warning",
          message: "暂无抽题数",
        });
        return;
      }
      if (
        (this.extractCount == 0 && i == 0) ||
        (this.extractCount == titlelist.questionCount && i)
      ) {
        this.$message({
          type: "warning",
          message: "已超出范围",
        });
        return;
      }
      this.extractCount =
        i == 1 ? this.extractCount + 1 : this.extractCount - 1;
    },
    drawQuestions() {
      var company = this.companyList.filter((i) => i.id == this.company);
      var data = {
        id: 0,
        companyId: this.company + "",
        companyName: company[0].companyName,
        deptId: 0,
        deptName: "",
        postId: "",
        postName: "",
        remark: this.remark,
        chargeUserId: this.$store.state.userInfo.id + "",
        planId: "",
        status: this.status * 1,
        saveTime: new Date().toISOString(),
        completeTime: new Date().toISOString(),
        saveUserId: this.$store.state.userInfo.id + "",
        saveUserNo: this.$store.state.userInfo.userNo,
        saveUserName: this.$store.state.userInfo.userName,
      };
      var n = this.typeBtnIndex == 0 ? "安全考核" : "客服考核";
      post(
        "/api/ExamineRules/InsertTbFileAudit?Name=" +
          n +
          "&ExtractCount=" +
          this.extractCount +
          "&Remark=" +
          this.remark,
        data
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "抽题成功",
            });
            this.getData();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("抽题失败");
        });
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    toTowPage(e) {
      console.log(e,77);
      sessionStorage.setItem("Fitem", JSON.stringify(e));
      this.$router.push(
        "./rules-list?Id=" +
          e.id +
          "&CpyID=" +
          e.companyId +
          "&typeBtnIndex=" +
          (this.typeBtnIndex == 1 ? "客服考核" : "安全考核") +
          "&status=" + e.status
      );
    },
    delItem() {
      this.$confirm(
        "此操作将永久删除该" + this.CurName + "考核细则",
        "是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteMethod("/api/ExamineRules?Id=" + this.CurId).then(() => {
            this.getData();
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toChoose() {
      this.dialogVisible = true;
      get(
        "/api/ExamineRules/GetTitleList?Name=" +
          (this.typeBtnIndex == 0 ? "安全考核" : "客服考核")
      ).then((resp) => {
        if (resp.code == 200) {
          this.titlelist = resp.data;
          this.extractCount = resp.data.questionCount;
        }
      });
    },

    handleRow(e) {
      this.CurId = e.id;
      this.CurName = "关于" + e.companyName;
      this.$refs.multipleTable.toggleRowSelection(e);
      this.selectChange(this.handleSelectionList);
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value;
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
      this.selectChange(this.handleSelectionList);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    changeTypeBtn(i) {
      this.typeBtnIndex = i;
      this.getData();
    },
    bindCheckBox() {
      // 有问题点不需调用接口
      if (this.status == 5) {
        get(
        "/api/ExamineRules/GetByFileAudit?CompId=" +
          this.company +
          "&Name=" +
          (this.typeBtnIndex == 0 ? "安全考核" : "客服考核") +
          "&Status=" +
          ''
      ).then((res) => {
        this.tableData = res.data;
        this.tableData = this.tableData.filter(item => item.failCount > 0);
      });
      return;
        // this.tableData = [];
        // get(
        //   "/api/ExamineRules/GetList?Name=" +
        //     (this.typeBtnIndex == 0 ? "安全考核" : "客服考核")
        // ).then((resp) => {
        //   if (resp.code == 200) {
        //     resp.data.map((item) => {
        //       if (item.failCount > 0) this.tableData.push(item);
        //     });
        //   }
        // });
        // return;
      }
      if (this.status == undefined) {
        this.status = 0;
      }
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
.select-box {
  display: flex;
  align-items: center;
  margin: 5px 0;
  img {
    margin-right: 10px;
  }
}
.box_ {
  display: flex;
  margin-top: 5px;
  ._item {
    width: 105px;
    height: 40px;
    color: #000000;
    background: #ffffff;
    border: 1px solid #e7e9ec;
    box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
  }
}

.mid_box {
  background-color: white;
  margin-bottom: 0 !important;
  padding: 15px;
}
.table {
  padding-top: 10px;
  border-top: 1px solid rgb(228, 228, 228);
  background-color: white;
}
::v-deep .select .el-input__inner {
  background-color: transparent !important;
  //   border-color: rgba(255, 255, 255, 0.5);
  color: #000000;
  border: 0px;
  height: 49px;
  width: 240px;
  font-size: 20px;
  text-size-adjust: 20px;
  .el-input__inner::placeholder {
    color: rgb(0, 0, 0);
    text-align: center;
  }
}
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
._Tab {
  color: #a09f9f;
  margin-bottom: 15px;
  padding-bottom: 10px;
  span {
    padding: 10px 0;
  }
  .activeColor {
    border-bottom: 2px solid #2f98ff;
    color: black;
  }
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
  }
}
</style>
<style scoped>
@import "../../assets/style/Examine_CSS.css";
</style>
